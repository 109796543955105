// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-all-our-members-js": () => import("./../../../src/pages/about-us/all-our-members.js" /* webpackChunkName: "component---src-pages-about-us-all-our-members-js" */),
  "component---src-pages-about-us-careers-js": () => import("./../../../src/pages/about-us/careers.js" /* webpackChunkName: "component---src-pages-about-us-careers-js" */),
  "component---src-pages-about-us-contact-us-js": () => import("./../../../src/pages/about-us/contact-us.js" /* webpackChunkName: "component---src-pages-about-us-contact-us-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-case-studies-cs-costimizer-js": () => import("./../../../src/pages/case-studies/cs-costimizer.js" /* webpackChunkName: "component---src-pages-case-studies-cs-costimizer-js" */),
  "component---src-pages-case-studies-cs-infominer-js": () => import("./../../../src/pages/case-studies/cs-infominer.js" /* webpackChunkName: "component---src-pages-case-studies-cs-infominer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-solutions-index-js": () => import("./../../../src/pages/solutions/index.js" /* webpackChunkName: "component---src-pages-solutions-index-js" */),
  "component---src-pages-solutions-infominer-js": () => import("./../../../src/pages/solutions/infominer.js" /* webpackChunkName: "component---src-pages-solutions-infominer-js" */),
  "component---src-pages-solutions-kabuddle-db-index-js": () => import("./../../../src/pages/solutions/kabuddle-db/index.js" /* webpackChunkName: "component---src-pages-solutions-kabuddle-db-index-js" */),
  "component---src-pages-solutions-kabuddle-db-sign-in-js": () => import("./../../../src/pages/solutions/kabuddle-db/sign-in.js" /* webpackChunkName: "component---src-pages-solutions-kabuddle-db-sign-in-js" */),
  "component---src-pages-solutions-kabuddle-db-sign-up-js": () => import("./../../../src/pages/solutions/kabuddle-db/sign-up.js" /* webpackChunkName: "component---src-pages-solutions-kabuddle-db-sign-up-js" */),
  "component---src-pages-solutions-materials-costimizer-js": () => import("./../../../src/pages/solutions/materials-costimizer.js" /* webpackChunkName: "component---src-pages-solutions-materials-costimizer-js" */)
}

